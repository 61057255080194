<template>
    <div class="carousel">
      <div
        class="carousel-container"
        :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
      >
        <div
          v-for="(slide, index) in slides"
          :key="index"
          class="carousel-slide"
        >
          <img :src="slide.image" :alt="slide.alt" />
        </div>
      </div>
  
      <!-- Navigation Buttons -->
      <button class="carousel-btn prev" @click="prevSlide">‹</button>
      <button class="carousel-btn next" @click="nextSlide">›</button>
  
      <!-- Indicators -->
      <div class="indicators">
        <span
          v-for="(slide, index) in slides"
          :key="index"
          class="indicator"
          :class="{ active: index === currentIndex }"
          @click="goToSlide(index)"
        ></span>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, onUnmounted } from "vue";
  
  export default {
    name: "CarouselSlider",
    props: {
      slides: {
        type: Array,
        required: true,
      },
      autoPlay: {
        type: Boolean,
        default: true,
      },
      interval: {
        type: Number,
        default: 3000,
      },
    },
    setup(props) {
      const currentIndex = ref(0);
      let autoPlayInterval;
  
      const nextSlide = () => {
        currentIndex.value = (currentIndex.value + 1) % props.slides.length;
      };
  
      const prevSlide = () => {
        currentIndex.value =
          (currentIndex.value - 1 + props.slides.length) % props.slides.length;
      };
  
      const goToSlide = (index) => {
        currentIndex.value = index;
      };
  
      const startAutoPlay = () => {
        if (props.autoPlay) {
          autoPlayInterval = setInterval(nextSlide, props.interval);
        }
      };
  
      const stopAutoPlay = () => {
        clearInterval(autoPlayInterval);
      };
  
      onMounted(startAutoPlay);
      onUnmounted(stopAutoPlay);
  
      return {
        currentIndex,
        nextSlide,
        prevSlide,
        goToSlide,
      };
    },
  };
  </script>
  
  <style scoped>
  /* Carousel Styles */
  .carousel {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }
  
  .carousel-container {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-slide {
    min-width: 100%;
    position: relative;
  }
  
  .carousel-slide img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  /* Navigation Buttons */
  .carousel-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    z-index: 10;
  }
  
  .carousel-btn.prev {
    left: 10px;
  }
  
  .carousel-btn.next {
    right: 10px;
  }
  
  /* Indicators */
  .indicators {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .indicator {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: gray;
    cursor: pointer;
  }
  
  .indicator.active {
    background-color: white;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
  
    .carousel-btn {
      font-size: 1.5rem;
    }
  
    .indicators {
      margin-top: 5px;
    }
  
    .indicator {
      width: 8px;
      height: 8px;
    }
  }
  </style>
  