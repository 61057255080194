<template>
  <div class="home">
  <CarouselSlider :slides="slides" :autoPlay="true" :interval="5000" />
  </div>
</template>

<script>
// @ is an alias to /src
import { ref } from "vue";
import CarouselSlider from "@/components/CarouselSlider.vue";
import slide01 from "@/assets/img/slide01.jpg"
import slide02 from "@/assets/img/slide02.jpg"

export default {
  name: 'HomeView',
  components: {
    CarouselSlider,
  },
  setup(){
    const slides = ref([
      {
        image: slide01,
        alt: "Slide 1"
      },
      {
        image: slide02,
        alt: "Slide 2"
      }
    ]);
    return {slides}
  }
}
</script>
