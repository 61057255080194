<template>
  <NavView />
  <div class="ctn">
    <router-view />
  </div>
</template>

<script>
import { ref } from "vue";
import NavView from "./components/NavView.vue";

export default {
  name: "TopNavBar",
  components: {
    NavView
  },
  setup() {
    const isMenuOpen = ref(false);
    const isHovered = ref(false);
    

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    const hover = (state) => {
      isHovered.value = state;
    };

    return {
      isMenuOpen,
      isHovered,
      toggleMenu,
      hover,
    };
  },
};
</script>
<style>
body{margin:0}
.ctn{position:relative; top:90px}
</style>