<template>
  <div class="navctn container">
    <nav class="navbar">
      <div class="container">
        <router-link  :to="{name : 'home'}" class="navbar-brand">
          EventsFrames.online
        </router-link>
        <button class="navbar-toggler" type="button" @click="toggleNav">
          ☰
        </button>
        <div class="navbar-collapse" :class="{ active: isNavOpen }" id="navigation">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link  :to="{name : 'home'}" class="nav-link"> Home</router-link>
            </li>
          </ul>
          <ul class="navbar-actions">
            <li>
              <a class="btn btn-outline" target="_blank" :href="urls.login">
                Sign In
              </a>
            </li>
            <li>
              <a :href="urls.signup" class="btn btn-dark" target="_blank" >
                Sign Up
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
  
<script>
  import { urls } from "@/assets/js/urls";
  export default {
    name: "NavView",
    setup(){
      function toggleNav() {
        this.isNavOpen = !this.isNavOpen;
      }
      return{urls,toggleNav}
    }
  };
</script>
  
  <style scoped>
  .container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

.collapse:not(.show) {
  display: none;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-sm,
.navbar>.container-md,
.navbar>.container-lg,
.navbar>.container-xl,
.navbar>.container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
  .navctn{    z-index: 9999;
    position: sticky !important;
    top: 0;
    width: 100%;}
  /* General Reset */
  body, ul, li, a, button {
    margin: 0; font-family: Inter, Helvetica, Arial, sans-serif;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Navbar Styles */
  .navbar {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
    right: 0 !important;
    left: 0 !important;
    top: 0 !important;
    position: absolute !important;
    border-radius: 0.75rem;
    -webkit-backdrop-filter: saturate(100%) blur(16px);
    backdrop-filter: saturate(100%) blur(16px);
    background-color: rgba(255, 255, 255, 0.9) !important;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    padding: 0.5rem 0.75rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #ddd;
  }
  
  .navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 0.5rem !important;
    padding-right: 0 !important;
  }
  
  .navbar-brand {
    font-size: 1.125rem;
    color: #262626;
    text-decoration: none;
    font-weight: 700;
    padding-top: 0.40625rem;
    padding-bottom: 0.40625rem;
    margin-right: 1rem;
  }
  
  .navbar-toggler {
    font-size: 24px;
    border: none;
    background: none;
    cursor: pointer;
    display:none
  }
  
  .navbar-collapse {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .navbar-nav {display: flex; list-style: none;    margin-right: auto !important; margin-left: auto !important; margin-bottom: 0;}
  
  .nav-item {
    margin: 0 10px;
  }
  
  .nav-link {
    text-decoration: none;
    color: #333;
    font-size: 14px;
    padding: 5px 10px;
    transition: color 0.3s ease;
  }
  
  .nav-link:hover {
    color: #007bff;
  }
  
  .nav-link.active {
    color: #007bff;
    font-weight: bold;
  }
  
  .navbar-actions {
    display: flex;
    align-items: center;
    gap: 10px; list-style:none;
  }
  
  .btn {    text-decoration: none;
    font-size: 0.75rem;
    border: 1px solid #333;
    border-radius: 0.5rem;
    background-size: 150%;
    background-position-x: 25%;
    display: inline-block;
    padding: 0.375rem 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    vertical-align: middle;
    user-select: none;
    transition: all 0.15s ease-in;
  }
  
  .btn-outline {
    background-color: transparent;
    color: #333;
  }
  
  .btn-outline:hover {
    background-color: #020b14;
    color: #fff;
  }
  
  .btn-dark {
    background-color: #333;
    color: #fff;
  }
  
  .btn-dark:hover {
    background-color: #000;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .navbar-collapse {
      display: none;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  
    .navbar-collapse.active {
      display: flex;
    }
  }
  

@media (min-width: 576px) {

  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-nav{
    flex-direction: row;
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}
  </style>
  