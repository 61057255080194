<template>
  <ImageEditor :bgImg="bg"/>
</template>

<script>
import ImageEditor from '@/components/ImageEditor.vue'
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import call from "../composables/call.js";
import { urls } from "../assets/js/urls";

export default {
  name: 'FrameView',
  components: {
    ImageEditor
  },
  setup(){
    const route = useRoute();
    const router = useRouter();
    const frame_id = computed(() => route.params.id)
    const bg=ref("")

    onMounted(() => {
      call({
        url:urls.frame,
        data:{getFrame:frame_id.value},
        success:(res)=>{
          console.log(res)
          if(res.status){
            bg.value=res.msg;
          }else{
            alert(res.msg)
            router.push('/');
          }
        }
      })
    })
    return{bg}
  }
}
</script>
